import Card from '@/components/Card.vue';
export default defineComponent({
  name: 'ProfileStatusBar',
  components: {
    Card: Card
  },
  setup: function setup() {
    var userStore = useUserStore();
    return {
      user: userStore
    };
  },
  computed: {
    readyness: function readyness() {
      if (!this.user.highestSignatureQuality) {
        return 'not_ready';
      } else {
        return this.user.highestSignatureQuality;
      }
    },
    percentage: function percentage() {
      if (this.user.highestSignatureQuality === 'demo' || !this.user.highestSignatureQuality) {
        return 0;
      } else if (this.user.highestSignatureQuality === 'qes') {
        return 100;
      } else if (this.user.highestSignatureQuality === 'ses') {
        var val = this.$vuetify.breakpoint.mdAndUp ? 15 : 33;
        return val;
      } else {
        var _val = this.$vuetify.breakpoint.mdAndUp ? 75 : 66;
        return _val;
      }
    },
    dynamicTranslations: function dynamicTranslations() {
      return {
        title: {
          aes: this.$t('personal_data.status_bar.title.aes'),
          demo: this.$t('personal_data.status_bar.title.demo'),
          not_ready: this.$t('personal_data.status_bar.title.not_ready'),
          qes: this.$t('personal_data.status_bar.title.qes'),
          ses: this.$t('personal_data.status_bar.title.ses')
        },
        href: {
          // TODO: no translation but possible: 'not_ready'
          aes: this.$t('personal_data.status_bar.link.aes.href'),
          demo: this.$t('personal_data.status_bar.link.demo.href'),
          qes: this.$t('personal_data.status_bar.link.qes.href'),
          ses: this.$t('personal_data.status_bar.link.ses.href')
        },
        text: {
          // TODO: no translation but possible: 'not_ready'
          aes: this.$t('personal_data.status_bar.link.aes.text'),
          demo: this.$t('personal_data.status_bar.link.demo.text'),
          qes: this.$t('personal_data.status_bar.link.qes.text'),
          ses: this.$t('personal_data.status_bar.link.ses.text')
        }
      };
    }
  }
});